<template>
  <div class="content">
    <BaseH1 :text="'Powiadomenia'" :short="true" />
    <SvgIllustrationsEmptyNotifications v-if="notifications.length === 0" />

    <div class="notifications-container">
      <Notification
        v-for="notification in notifications"
        :key="notification.name"
        :to="notification.to"
        :icon-component="notification.iconComponent"
        :title="notification.title"
        :description="notification.description"
      />
    </div>

    <SvgIllustrationsBgNotifications class="bg-illustration" />
    <SvgIllustrationsBgWhiteNotifications class="bg-white" />

    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useNotifications from "@/composables/useNotifications";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import NavMobile from "@/components/NavMobile.vue";
import SvgIllustrationsEmptyNotifications from "@/components/Svg/Illustrations/SvgIllustrationsEmptyNotifications.vue";
import useIsMobile from "@/composables/useIsMobile";
import Notification from "@/components/Notification/Notification.vue";
import SvgIllustrationsBgNotifications from "@/components/Svg/Illustrations/SvgIllustrationsBgNotifications.vue";
import SvgIllustrationsBgWhiteNotifications from "@/components/Svg/Illustrations/SvgIllustrationsBgWhiteNotifications.vue";

export default {
  components: {
    NavMobile,
    SvgIllustrationsEmptyNotifications,
    Notification,
    SvgIllustrationsBgNotifications,
    SvgIllustrationsBgWhiteNotifications,
    BaseH1,
  },

  setup() {
    const { isMobile } = useIsMobile();

    const { notifications } = useNotifications();

    return {
      isMobile,
      notifications,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  padding: 60px 0 0;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}
.notifications-container {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
.bg-illustration {
  bottom: 55px;
  right: 25px;
  position: absolute;
  z-index: -1;
}
.bg-white {
  top: var(--header-default--height);
  left: 0px;
  position: fixed;
  width: 100vw;
  z-index: -2;
}

@media (max-width: 1200px) {
  .content {
    padding: 30px 0px 80px;
    display: flex;
    flex-flow: column;
    row-gap: 30px;
  }
  .bg-illustration {
    display: none;
  }
  .bg-white {
    display: none;
  }
}
</style>
