<template>
  <svg
    width="408"
    height="429"
    viewBox="0 0 408 429"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-illustration"
  >
    <g clip-path="url(#clip0_5806_138)">
      <path
        d="M340.432 250.161C319.799 259.514 308.165 269.21 308.165 269.21L297.349 292.905C297.349 292.905 309.674 300.584 316.839 301.758C323.878 303.265 333.504 301.942 332.923 299.441C332.676 297.066 327.47 294.728 327.47 294.728C327.47 294.728 346.84 297.831 348.803 293.625C350.765 289.419 340.477 287.45 340.477 287.45C346.645 285.206 375.479 282.361 350.903 276.92C345.988 275.831 351.903 277.296 361.739 273.39C361.739 273.39 371.451 269.816 370.039 265.482C368.96 261.273 357.042 263.635 357.042 263.635C357.042 263.635 374.306 248.071 369.645 243.275C365.317 238.604 361.065 240.808 340.432 250.161Z"
        fill="#9A96E6"
      />
      <path
        d="M133.667 118.375C125.377 117.158 120.76 149.651 120.76 149.651C120.76 149.651 114.525 135.896 109.274 137.723C104.355 139.676 107.384 151.845 107.384 151.845C110.413 164.014 116.407 169.312 111.495 165.182C88.3929 144.701 106.152 175.342 108.435 183.427C108.435 183.427 99.32 174.294 96.523 179.707C93.3928 184.995 110.874 202.22 110.874 202.22C110.874 202.22 104.919 198.839 102.168 200.086C99.4172 201.333 105.197 212.255 111.399 218.011C117.809 224.226 134.217 231.159 134.217 231.159L150.495 203.052C150.495 203.052 139.259 190.458 138.198 168.001C140.314 136.089 146.746 121.013 133.667 118.375Z"
        fill="#9A96E6"
      />
      <path
        d="M285.46 347.764C285.335 348.097 284.876 348.305 284.543 348.179L272.548 343.667L104.626 280.499C104.293 280.374 104.085 279.916 104.211 279.582C108.429 269.379 118.067 255.89 127.877 237.902C158.1 180.812 175.842 146.791 211.929 139.829C222.597 137.757 233.305 137.601 242.136 139.402C243.68 137.32 244.144 131.029 245.064 127.572C247.03 120.325 243.992 117.28 245.456 111.365C247.089 103.993 250.093 99.0378 258.472 94.9634C263.182 92.5518 273.639 93.0625 279.303 95.1932C280.303 95.5692 280.969 95.8198 281.969 96.1958C284.842 97.657 287.465 99.7845 289.629 102.12C297.078 110.627 297.234 121.335 293.767 129.539C290.551 137.077 286.51 136.697 282.671 142.86C280.751 145.941 280.791 147.857 280.788 150.899C287.949 155.114 294.567 161.787 300.809 169.46C328.48 203.069 314.721 253.801 301.506 302.075C296.028 321.693 290.137 337.352 285.46 347.764Z"
        fill="#F8A815"
      />
      <path
        d="M176.076 282.656C174.701 283.279 173.658 284.028 172.741 284.444C157.356 295.01 150.588 313.002 155.198 328.047C162.008 350.386 192.201 362.124 211.838 349.354C226.847 339.787 232.077 317.794 223.595 300.911C213.49 282.277 189.706 276.753 176.076 282.656Z"
        fill="#FCBE0E"
      />
      <path
        d="M278.012 96.6077C280.885 98.0689 283.175 100.071 285.339 102.406C292.455 110.788 293.026 122.413 289.144 129.7C285.638 135.988 280.68 136.024 277.507 142.437C275.92 145.643 275.459 148.893 275.789 152.06C282.492 156.483 289.486 162.156 295.394 169.703C321.274 203.019 307.515 253.751 293.716 302.566C288.238 322.184 282.222 338.177 278.003 348.38C277.878 348.713 277.419 348.921 277.086 348.796L265.758 344.534L104.5 283.873C104.166 283.748 103.959 283.289 104.084 282.956C108.302 272.753 115.318 257.136 124.794 239.023C154.809 181.475 174.013 144.58 208.768 137.117C219.102 134.919 229.434 135.763 238.057 137.105C239.601 135.024 241.187 131.817 242.107 128.36C244.073 121.113 241.368 118.193 242.499 112.153C244.131 104.781 250.722 97.3712 258.932 94.7549C264.184 92.9271 270.141 93.2665 275.472 95.2719C277.138 95.8985 278.012 96.6077 278.012 96.6077Z"
        fill="#FFCC07"
      />
      <path
        d="M278.118 357.172L94.5364 288.113C84.8742 284.479 80.2191 273.6 83.8538 263.938C87.4884 254.275 98.3674 249.62 108.03 253.255L291.611 322.313C301.273 325.948 305.929 336.827 302.294 346.489C298.785 355.818 287.78 360.807 278.118 357.172Z"
        fill="#F8A815"
      />
      <path
        d="M191.541 196.053C194.711 192.682 200.335 192.896 204.333 194.4C208.331 195.904 212.577 199.783 212.281 204.616C211.944 207.532 216.652 208.162 217.113 204.912C218.211 190.873 196.887 182.852 188.044 193.217C187.335 194.091 187.5 195.674 188.707 196.509C189.456 197.551 190.706 197.261 191.541 196.053Z"
        fill="#F39323"
      />
      <path
        d="M250.057 218.445C253.226 215.073 258.851 215.288 262.849 216.792C266.847 218.296 271.093 222.175 270.796 227.007C270.46 229.923 275.167 230.553 275.629 227.304C276.727 213.264 255.403 205.243 246.56 215.608C245.851 216.482 246.016 218.066 247.223 218.9C247.764 219.484 249.014 219.194 250.057 218.445Z"
        fill="#F39323"
      />
      <path
        d="M215.483 250.698C216.65 249.616 222.226 257.038 231.475 256.714C236.1 256.552 238.769 254.513 239.643 255.223C240.933 256.849 235.382 266.551 228.507 266.627C219.257 266.951 213.856 251.988 215.483 250.698Z"
        fill="#F7A813"
        stroke="#F7A813"
        stroke-width="0.6586"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_5806_138">
        <rect
          width="355.973"
          height="355.973"
          fill="white"
          transform="translate(125.332) rotate(20.615)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
@media (max-width: 1200px) {
  .svg-illustration {
    width: 90%;
  }
}
</style>
